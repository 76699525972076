import {API} from 'aws-amplify';
import axios from "axios";
export async function resetPasswordApi(queryObj: any) {
  return API.post('Backend', "/user/resetPassword", {body: queryObj});
}
export async function forgotPasswordApi(email: any) {
  return API.post('Backend', "/user/forgotPassword", {body: email});
}
export async function resendVerifyEmailApi(email: any) {
  return API.post('Backend', "/user/resendVerify", {body: email});
}
export async function verifyApi(token: any) {
  return API.post('Backend', "/user/verify", {body: token});
}
export async function loginUserApi(user: any) {
  return API.post('Backend', "/user/login", {body: user});
}
export const checkClient = async ({email}:{email:string}) => {
  try {
    // console.log("Calling checkClient with email:", email);
    const response = await axios.post(`${process.env.REACT_APP_APIURL}/user/checkClient`, {
      email, 
    });
    return response.data; 
  } catch (error) {
    console.error("Error in checkClient:", error);
    throw error;
  }
};